<template>
  <ValidationObserver tag="span" ref="covObserver">
    <div v-if="showControl">
      <div class="section" style="margin-bottom: 1cm;">
        <h3>Valuation</h3>
        <v-row class="my-0" v-if="showCovRow('A')">
          <v-col md="9" cols="12" class="py-0 pb-sm-4 pb-xs-4">
            <DynamicFormControl :control="{
              name: 'BuildingValuationAmount',
              type: 'TextInput',
              value: buildingValuationAmount,
              config: {
                label: 'Building Estimated Valuation',
                rules: 'required|min_value:1|max_value:999999999',
                short: true,
                col: 8,
                prefix: '$',
                default: 0,
                currency: true,
                disabled: disabled,
                min: 0,
                allowNegative: false,
                defaultValue: 0,
              }
            }" @change="buildingValuationChange($event)" @blur="buildingValuationBlur($event)" />
          </v-col>
        </v-row>
        <v-row class="my-0" v-if="showBuildingValuationAttestationChange">
          <v-col md="12" cols="12" class="py-0 pb-2">
            <DynamicFormControl :control="{
              name: 'ValuationAttestation',
              type: 'Attestation',
              value: valuationAttestation,
              config: {
                label: valuationAttestationLabel,
                rules: 'required',
                col: 12,
                disabled: disabled,
                short: true
              }
            }" @change="valuationAttestationChange($event)" />
          </v-col>
        </v-row>
        <v-row class="my-0" v-if="showCovRow('C')">
          <v-col md="9" cols="12" class="py-0 pb-sm-4 pb-xs-4">
            <DynamicFormControl :control="{
              name: 'ContentsValuationAmount',
              type: 'TextInput',
              value: contentsValuationAmount,
              config: {
                label: 'Personal Property Estimated Valuation',
                rules: 'required|min_value:0|max_value:999999999',
                short: true,
                col: 8,
                prefix: '$',
                default: 0,
                currency: true,
                disabled: disabled,
                min: 0,
                allowNegative: false,
                defaultValue: 0,
                hideDataServiceValue: true,
                ignoreDataServiceValue: true
              }
            }" @change="contentsValuationChange($event)" />
          </v-col>
        </v-row>
      </div>

      <div class="section">
        <h3>Limits</h3>
        <v-row class="my-0" v-if="showCovRow('A')">
          <v-col md="9" cols="12" class="py-0 pb-sm-4 pb-xs-4">
            <DynamicFormControl :control="{
              name: 'CovA',
              type: 'TextInput',
              value: Coverages.A,
              config: {
                label: 'Dwelling Coverage',
                rules: 'required|min_value:10000|max_value:5000000|greaterThanBuildingValuation',
                short: true,
                col: 8,
                prefix: '$',
                default: 0,
                currency: true,
                min: 0,
                allowNegative: false,
                defaultValue: 0,
                hideDataServiceValue: true,
                ignoreDataServiceValue: true
              }
            }" @change="coverageChange('A', $event)" @keydown="CovAKeydown" @blur="CovABlur" />
          </v-col>
        </v-row>
        <v-row class="my-0" v-if="showCovRow('B')">
          <v-col md="9" cols="12" class="py-0 pb-sm-4 pb-xs-4">
            <DynamicFormControl :control="{
              name: 'CovB',
              type: 'TextInput',
              value: Coverages.B,
              config: {
                label: 'Other Structures Coverage',
                rules: 'required|min5000IfSelected|max_value:5000000',
                col: 8,
                prefix: '$',
                default: 0,
                currency: true,
                min: 0,
                allowNegative: false,
                defaultValue: 0,
                hideDataServiceValue: true,
                ignoreDataServiceValue: true
              }
            }" @change="coverageChange('B', $event)" />
          </v-col>
        </v-row>
        <v-row class="my-0" v-if="showCovRow('C')">
          <v-col md="9" cols="12" class="py-0 pb-sm-4 pb-xs-4">
            <DynamicFormControl :control="{
              name: 'CovC',
              type: 'TextInput',
              value: Coverages.C,
              config: {
                label: 'Personal Property Coverage',
                rules: 'required|min5000IfSelected|max_value:5000000|greaterThanContentsValuation',
                col: 8,
                prefix: '$',
                default: 0,
                currency: true,
                min: 0,
                allowNegative: false,
                defaultValue: 0,
                hideDataServiceValue: true,
                ignoreDataServiceValue: true
              }
            }" @change="coverageChange('C', $event)" @blur="CovCChange" />
          </v-col>
        </v-row>
        <v-row class="my-0" v-if=showValuationToggle>
          <v-col md="12" cols="8" class="py-0 pb-2">
            <DynamicFormControl :control="{
              name: 'ValuationType',
              type: 'RadioButtonGroup',
              value: valuationType,
              config: {
                label: 'Replacement Cost or Actual Cost for Personal Property Limit?',
                rules: 'required',
                col: 8,
                indented: true,
                default: 'RCV',
                options: [
                  {
                    value: 'RCV',
                    text: 'Replacement Cost Valuation'
                  },
                  {
                    value: 'ACV',
                    text: 'Actual Cost Valuation'
                  }
                ]
              }
            }" @change="valuationTypeChange($event)" />
          </v-col>
        </v-row>
        <v-row class="my-0" v-if="showCovRow('D')">
          <v-col md="9" cols="12" class="py-0 pb-sm-4 pb-xs-4">
            <DynamicFormControl :control="{
              name: 'CovD',
              type: 'TextInput',
              value: Coverages.D,
              config: {
                label: 'Loss of Use Coverage',
                rules: 'required|min5000IfSelected|max_value:5000000',
                col: 8,
                prefix: '$',
                default: 0,
                currency: true,
                min: 0,
                allowNegative: false,
                defaultValue: 0,
                hideDataServiceValue: true,
                ignoreDataServiceValue: true
              }
            }" @change="coverageChange('D', $event)" />
          </v-col>
        </v-row>
      </div>
    </div>

  </ValidationObserver>
</template>

<script>
  import DynamicFormControl from '@/components/form/DynamicFormControl.vue'
  import { formatMoney } from '@/scripts/helper'

  export default {
    name: "Coverages",
    components: {
      DynamicFormControl
    },
    props: {
      module: String,
      disabled: Boolean,
    },
    data() {
      return {
        CoverageOption: null,
        showControl: false,
        valuationAttestation: null,
        showValuationToggle: false,
        buildingValuationAmount: null,
        contentsValuationAmount: null,
        valuationType: null,
        showBuildingValuationAttestationChange: false,
        CoverageObjects: {
          A: {},
          B: {},
          C: {},
          D: {}
        },
        Coverages: {
          A: null,
          B: null,
          C: null,
          D: null
        },
        Percents: {
          A: null,
          B: null,
          C: null,
          D: null,
        }
      }
    },
    methods: {
      formatMoney,
      showCovRow(type) {
        return true;
      },
      coverageChange(cov, event) {
        if (!this.showCovRow(cov)) return;
        if (event.value == this.Coverages[cov]) return;
        this.updateCoverageControl(cov, event.value);
      },
      getDefaultPercents() {
        switch (this.CoverageOption) {
          case 'flood':
            return {
              B: 0,
              C: 0,
              D: 0,
              Edp3: 0
            }
          default:
            return {
              B: 0,
              C: 0,
              D: 0,
              Edp3: 0
            }
        }
      },
      updateCoverageControl(cov, value) {
        this.$store.commit('updateFormControl', {
          module: this.module,
          name: 'Cov' + cov,
          value: value
        });
      },
      valuationAttestationChange(event) {
        this.$store.commit('updateFormControl', {
          module: this.module,
          name: 'ValuationAttestation',
          value: event.value
        });
      },
      buildingValuationChange(event) {
        this.$store.commit('updateFormControl', {
          module: this.module,
          name: 'BuildingValuationAmount',
          value: event.value
        });
        if (this.Coverages.A > 0)
          this.$refs.covObserver.validate();
      },
      contentsValuationChange(event) {
        this.$store.commit('updateFormControl', {
          module: this.module,
          name: 'ContentsValuationAmount',
          value: event.value
        });
        if (this.Coverages.C > 0)
          this.$refs.covObserver.validate();
      },
      valuationTypeChange(event) {
        this.$store.commit('updateFormControl', {
          module: this.module,
          name: 'ValuationType',
          value: event.value
        });
      },
      CovABlur() {

        //Update CovA min/max metadata after CovA control is blurred/changed
        if (this.Coverages.A || this.Coverages.A === 0) {

          const covAControl = this.CoverageObjects.A

          let covAMetadata = {};
          if (covAControl.metadata) {
            covAMetadata = covAControl.metadata;
          }

          if (covAMetadata.min === undefined || covAMetadata.min === null || this.Coverages.A < parseInt(covAMetadata.min)) {
            covAMetadata.min = this.Coverages.A;
          }

          if (covAMetadata.max === undefined || covAMetadata.max === null || this.Coverages.A > parseInt(covAMetadata.max)) {
            covAMetadata.max = this.Coverages.A;
          }

          this.$store.commit('updateFormControlMetadata', {
            module: this.module,
            name: 'CovA',
            metadata: covAMetadata
          });
        }


      },
      CovAKeydown() {
        setTimeout(() => {
          this.CovAChange();
        }, 100)
      },
      CovAChange() {

        if (!this.CovA) {
          this.Coverages.A = 0;
        }


      },
      CovCChange() {
        if (this.Coverages.C > 0) {
          this.showValuationToggle = true;
        } else {
          this.showValuationToggle = false;
          // set ValuationType to null
          this.$store.commit('updateFormControl', {
            module: this.module,
            name: 'ValuationType',
            value: null
          });
        }
      },
      buildingValuationBlur() {
        this.showBuildingValuationAttestationChange = this.checkShowValuationAttestationChange();
      },
      checkShowValuationAttestationChange() {
        let show = false;
        let coreLogicRCV = this.$store.getters.getControlValue("quoteForm", 'ValuationRCV');

        if (
          this.buildingValuationAmount &&
          coreLogicRCV &&
          this.buildingValuationAmount > 0 &&
          this.buildingValuationAmount < (coreLogicRCV * .9)) {
          show = true;
        }

        return show;
      },
      initialize() {
        this.showControl = true;

        this.CovABlur();
      },
      syncControlValues() {
        const CovA = this.$store.getters.getControlValue(this.module, 'CovA')
        const CovB = this.$store.getters.getControlValue(this.module, 'CovB')
        const CovC = this.$store.getters.getControlValue(this.module, 'CovC')
        const CovD = this.$store.getters.getControlValue(this.module, 'CovD')
        const BuildingValuationAmount = this.$store.getters.getControlValue(this.module, 'BuildingValuationAmount');
        const ContentsValuationAmount = this.$store.getters.getControlValue(this.module, 'ContentsValuationAmount');
        const ValuationType = this.$store.getters.getControlValue(this.module, 'ValuationType');

        if ((CovA || CovA === 0) && CovA !== this.CovA) this.Coverages.A = CovA;
        if ((CovB || CovB === 0) && CovB !== this.CovB) this.Coverages.B = CovB;
        if ((CovC || CovC === 0) && CovC !== this.CovC) this.Coverages.C = CovC;
        if ((CovD || CovD === 0) && CovD !== this.CovD) this.Coverages.D = CovD;
        if ((BuildingValuationAmount || BuildingValuationAmount === 0) && BuildingValuationAmount !== this.buildingValuationAmount) this.buildingValuationAmount = BuildingValuationAmount;
        if ((ContentsValuationAmount || ContentsValuationAmount === 0) && ContentsValuationAmount !== this.contentsValuationAmount) this.contentsValuationAmount = ContentsValuationAmount;
        if (ValuationType !== this.valuationType) this.valuationType = ValuationType;

        this.CoverageObjects.A = this.$store.getters.getControl(this.module, 'CovA')
        this.CoverageObjects.B = this.$store.getters.getControl(this.module, 'CovB')
        this.CoverageObjects.C = this.$store.getters.getControl(this.module, 'CovC')
        this.CoverageObjects.D = this.$store.getters.getControl(this.module, 'CovD')

        this.valuationAttestation = this.$store.getters.getControlValue(this.module, 'ValuationAttestation');
        this.CovCChange();

        this.$forceUpdate();
      }
    },
    computed: {
      CovA() {
        return this.Coverages.A;
      },
      CovB() {
        return this.Coverages.B;
      },
      CovC() {
        return this.Coverages.C;
      },
      CovD() {
        return this.Coverages.D;
      },
      ValuationRCV() {
        return this.$store.getters.getControlValue(this.module, 'ValuationRCV');
      },
      valuationAttestationLabel() {
        const valuationRcv = this.$store.getters.getControlValue(this.module, 'ValuationRCV');
        if (valuationRcv && valuationRcv > 0) {
          return 'Public records indicate the property is valued at ' + this.formatMoney(valuationRcv) + '  Please confirm your valuation is accurate.';
        } else {
          return 'Public records indicate the property is valued greater than the entered amount. Please confirm your valuation is accurate.';
        }
      }
    },
    mounted() {
      this.CoverageOption = this.$store.getters.getControlValue(this.module, 'CoverageOption')

      if (!this.buildingValuationAmount) {
        this.buildingValuationAmount = this.ValuationRCV
      }

      this.$store.watch(() => {
        this.syncControlValues();
      }, () => { },
        {
          immediate: true
        });
      this.showBuildingValuationAttestationChange = this.checkShowValuationAttestationChange();

      this.initialize();

    },
  }
</script>
