var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationObserver',{ref:"covObserver",attrs:{"tag":"span"}},[(_vm.showControl)?_c('div',[_c('div',{staticClass:"section",staticStyle:{"margin-bottom":"1cm"}},[_c('h3',[_vm._v("Valuation")]),(_vm.showCovRow('A'))?_c('v-row',{staticClass:"my-0"},[_c('v-col',{staticClass:"py-0 pb-sm-4 pb-xs-4",attrs:{"md":"9","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
            name: 'BuildingValuationAmount',
            type: 'TextInput',
            value: _vm.buildingValuationAmount,
            config: {
              label: 'Building Estimated Valuation',
              rules: 'required|min_value:1|max_value:999999999',
              short: true,
              col: 8,
              prefix: '$',
              default: 0,
              currency: true,
              disabled: _vm.disabled,
              min: 0,
              allowNegative: false,
              defaultValue: 0,
            }
          }},on:{"change":function($event){return _vm.buildingValuationChange($event)},"blur":function($event){return _vm.buildingValuationBlur($event)}}})],1)],1):_vm._e(),(_vm.showBuildingValuationAttestationChange)?_c('v-row',{staticClass:"my-0"},[_c('v-col',{staticClass:"py-0 pb-2",attrs:{"md":"12","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
            name: 'ValuationAttestation',
            type: 'Attestation',
            value: _vm.valuationAttestation,
            config: {
              label: _vm.valuationAttestationLabel,
              rules: 'required',
              col: 12,
              disabled: _vm.disabled,
              short: true
            }
          }},on:{"change":function($event){return _vm.valuationAttestationChange($event)}}})],1)],1):_vm._e(),(_vm.showCovRow('C'))?_c('v-row',{staticClass:"my-0"},[_c('v-col',{staticClass:"py-0 pb-sm-4 pb-xs-4",attrs:{"md":"9","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
            name: 'ContentsValuationAmount',
            type: 'TextInput',
            value: _vm.contentsValuationAmount,
            config: {
              label: 'Personal Property Estimated Valuation',
              rules: 'required|min_value:0|max_value:999999999',
              short: true,
              col: 8,
              prefix: '$',
              default: 0,
              currency: true,
              disabled: _vm.disabled,
              min: 0,
              allowNegative: false,
              defaultValue: 0,
              hideDataServiceValue: true,
              ignoreDataServiceValue: true
            }
          }},on:{"change":function($event){return _vm.contentsValuationChange($event)}}})],1)],1):_vm._e()],1),_c('div',{staticClass:"section"},[_c('h3',[_vm._v("Limits")]),(_vm.showCovRow('A'))?_c('v-row',{staticClass:"my-0"},[_c('v-col',{staticClass:"py-0 pb-sm-4 pb-xs-4",attrs:{"md":"9","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
            name: 'CovA',
            type: 'TextInput',
            value: _vm.Coverages.A,
            config: {
              label: 'Dwelling Coverage',
              rules: 'required|min_value:10000|max_value:5000000|greaterThanBuildingValuation',
              short: true,
              col: 8,
              prefix: '$',
              default: 0,
              currency: true,
              min: 0,
              allowNegative: false,
              defaultValue: 0,
              hideDataServiceValue: true,
              ignoreDataServiceValue: true
            }
          }},on:{"change":function($event){return _vm.coverageChange('A', $event)},"keydown":_vm.CovAKeydown,"blur":_vm.CovABlur}})],1)],1):_vm._e(),(_vm.showCovRow('B'))?_c('v-row',{staticClass:"my-0"},[_c('v-col',{staticClass:"py-0 pb-sm-4 pb-xs-4",attrs:{"md":"9","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
            name: 'CovB',
            type: 'TextInput',
            value: _vm.Coverages.B,
            config: {
              label: 'Other Structures Coverage',
              rules: 'required|min5000IfSelected|max_value:5000000',
              col: 8,
              prefix: '$',
              default: 0,
              currency: true,
              min: 0,
              allowNegative: false,
              defaultValue: 0,
              hideDataServiceValue: true,
              ignoreDataServiceValue: true
            }
          }},on:{"change":function($event){return _vm.coverageChange('B', $event)}}})],1)],1):_vm._e(),(_vm.showCovRow('C'))?_c('v-row',{staticClass:"my-0"},[_c('v-col',{staticClass:"py-0 pb-sm-4 pb-xs-4",attrs:{"md":"9","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
            name: 'CovC',
            type: 'TextInput',
            value: _vm.Coverages.C,
            config: {
              label: 'Personal Property Coverage',
              rules: 'required|min5000IfSelected|max_value:5000000|greaterThanContentsValuation',
              col: 8,
              prefix: '$',
              default: 0,
              currency: true,
              min: 0,
              allowNegative: false,
              defaultValue: 0,
              hideDataServiceValue: true,
              ignoreDataServiceValue: true
            }
          }},on:{"change":function($event){return _vm.coverageChange('C', $event)},"blur":_vm.CovCChange}})],1)],1):_vm._e(),(_vm.showValuationToggle)?_c('v-row',{staticClass:"my-0"},[_c('v-col',{staticClass:"py-0 pb-2",attrs:{"md":"12","cols":"8"}},[_c('DynamicFormControl',{attrs:{"control":{
            name: 'ValuationType',
            type: 'RadioButtonGroup',
            value: _vm.valuationType,
            config: {
              label: 'Replacement Cost or Actual Cost for Personal Property Limit?',
              rules: 'required',
              col: 8,
              indented: true,
              default: 'RCV',
              options: [
                {
                  value: 'RCV',
                  text: 'Replacement Cost Valuation'
                },
                {
                  value: 'ACV',
                  text: 'Actual Cost Valuation'
                }
              ]
            }
          }},on:{"change":function($event){return _vm.valuationTypeChange($event)}}})],1)],1):_vm._e(),(_vm.showCovRow('D'))?_c('v-row',{staticClass:"my-0"},[_c('v-col',{staticClass:"py-0 pb-sm-4 pb-xs-4",attrs:{"md":"9","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
            name: 'CovD',
            type: 'TextInput',
            value: _vm.Coverages.D,
            config: {
              label: 'Loss of Use Coverage',
              rules: 'required|min5000IfSelected|max_value:5000000',
              col: 8,
              prefix: '$',
              default: 0,
              currency: true,
              min: 0,
              allowNegative: false,
              defaultValue: 0,
              hideDataServiceValue: true,
              ignoreDataServiceValue: true
            }
          }},on:{"change":function($event){return _vm.coverageChange('D', $event)}}})],1)],1):_vm._e()],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }