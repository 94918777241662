import { render, staticRenderFns } from "./FloodCoverages.vue?vue&type=template&id=d6e2f364&"
import script from "./FloodCoverages.vue?vue&type=script&lang=js&"
export * from "./FloodCoverages.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports